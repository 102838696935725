.backtest {
  background-color: #094442;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.textbacktest {
  color: white;
}
button:hover .textbacktest {
  color: rebeccapurple;
}

.order_number {
  padding: 8px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.order_number a {
  text-decoration: none;
  color: #25a09b;
  font-weight: bold;
  width: 100%;
  transition: color 0.3s;
}

.order_number:hover {
  background-color: rebeccapurple !important;
}

.order_number:hover a {
  color: white;
}

.signin_signup {
  padding: 20px 25px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

.signin {
  background-color: #094442;
  color: white !important;
}
.signin:hover {
  background-color: white;
  color: #094442 !important;
  border: none;
}
.signup {
  color: #094442 !important;
}
.signup:hover {
  background-color: #094442 !important;
  color: white !important;
}

.logo {
  height: 60px;
}
.drawer-slide-out {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.drawer-slide-in {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.custom-menu.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.custom-menu.vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.custom-menu li {
  text-align: center;
  margin: 10px 0;
  position: relative;
}

.custom-menu li a {
  display: block;
  padding: 10px 15px;
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: color 0.3s;
  position: relative;
}

.custom-menu li a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  height: 2px;
  background-color: #25a09b;
  transition: width 0.3s ease;
  width: 0;
}

.custom-menu li a:hover::after {
  width: 100%;
}

.header_background_image {
  width: 100%;
  height: 550px;
  background-image: url("../../public/images/chart.png"); /* Đường dẫn đến background image */
  background-size: cover; /* Đảm bảo background image sẽ cover hết khu vực */
  background-position: center;
  z-index: 0;
  position: relative;
}

.hexagon {
  width: 100%;
  height: 100%;
  background-color: #25a09b; /* Màu nền của hình tứ giác */
  clip-path: polygon(0% 0%, 60% 0%, 80% 100%, 0% 100%);
}

.user-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
}

.user-name {
  color: white;
  font-size: 1.125rem; /* text-lg */
  font-weight: 600; /* font-semibold */
}

.icon_feature {
  font-size: 25px;
  background-color: #76ceca;
  padding: 10px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.button_signup {
  margin-top: 20px;
  padding: 15px 28px;
  font-size: 1.1rem;
  background-color: #76ceca;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
  border-radius: 10px;
}

.button_signup:hover {
  color: white;
  background-color: #094442;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: marquee 20s linear infinite;
}
